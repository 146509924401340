// Import our custom CSS
import '../scss/theme.scss'

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

function navbarBehaviour() {

    document.addEventListener('DOMContentLoaded', function() {

        var navbarFixed = document.querySelector("header .navbar.js--hideScroll");
        var header = document.querySelector("header");
        // Trovo l'altezza della navbar

        if (navbarFixed) {
            var navbarHeight = navbar.offsetHeight;

            // Aggiungo l'altezza della navbar come padding all'elemento main
            var mainContent = document.querySelector('main.main-content');
            mainContent.style.paddingTop = navbarHeight + 'px';

            // Salvo la posizione dello scroll verticale
            var prevScrollpos = window.pageYOffset;
            var scrolledDown = false;

            // Evento scroll sull'oggetto window
            window.onscroll = function () {
                var currentScrollPos = window.pageYOffset;

                // Se l'user scrolla +200px si attiva l'effetto
                if (currentScrollPos > 200) {
                    scrolledDown = true;
                } else {
                    scrolledDown = false;
                }

                if (scrolledDown) {
                    if (prevScrollpos > currentScrollPos) {
                        navbarFixed.style.top = '0';
                    } else {
                        navbarFixed.style.top = '-' + navbarHeight + 'px';
                    }
                }

                prevScrollpos = currentScrollPos;
            };
        }


        if (header) {
            // Ottieni l'altezza dell'header
            var navbarHeight = header.offsetHeight;

            // Seleziona l'elemento main
            var mainContent = document.querySelector("main");

            if (mainContent) {
                // Aggiungi l'altezza dell'header in negativo al margine superiore del main
                mainContent.style.marginTop = "-" + navbarHeight + "px";
            }
        }


        // Quando si attiva il menu mobile, aggiungo una classe per rimuovere mix-blend-mode nell'header
        var togglerOpen = document.querySelector('button.navbar-toggler');
        var togglerClosed = document.querySelector('button.btn-close');

        function toggleHeaderBg(add) {
            document.body.classList.toggle('js--restoreHeaderBg', add);
        }

        if (togglerOpen) {
            togglerOpen.addEventListener('click', function() {
                toggleHeaderBg(true);
            });
        }
        if (togglerClosed) {
            togglerClosed.addEventListener('click', function() {
                toggleHeaderBg(false);
            });
        }
    });    

}
navbarBehaviour();


function smoothScroll(){
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
    
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth',
                top: 100,
            });
        });
    });
}
smoothScroll();








jQuery(function ($) {

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 150) {
            //clearHeader, not clearheader - caps H
            $("body").addClass("is-scrolled");
        } else {
            $("body").removeClass("is-scrolled");
        }
    }); //missing );


}); // jQuery End


const swiper_default = new Swiper('.swiper--default', {
    loop: false,
    // autoplay: {
    //     delay: 3000,
    // },
    spaceBetween: 0,
    speed: 600,
    slidesPerView: 1,
});





function gsapPortfolio(){

    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.batch(".gs--fadeInFromUp", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, y: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInFromDown", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, y: 0, duration: 1 });
        },
    });


    ScrollTrigger.batch(".gs--fadeInFromRight", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInFromLeft", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInDiagonalFromLeft", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, y: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInDiagonalFromRight", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, y: 0, duration: 1 });
        },
    });
    

    ScrollTrigger.batch(".gs--maskUp", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", y: 0, duration: 1.5, scrub: true });
        },
    });

    ScrollTrigger.batch(".gs--bgVariable", {
        start: "top 35%",
        onEnter: (elements, triggers) => {
            elements.forEach(element => {
                let tl = gsap.timeline();
                tl.to(element, {backgroundColor: "#FDF9E9", duration: 1})
                  .to(element.querySelector(".is-animated-color-title"), {color: "#0c0c0c", duration: 0.5}, "-=0.65")
                  .to(element.querySelector(".is-animated-color-editor"), {opacity: 1, y: 0, duration: 0.45, }, "-=0.35")
                  .to(element.querySelector(".is-animated-color-cta"), {opacity: 1, x: 0, duration: 0.35, }, "+=0.35");
            });
        },
    });


    ScrollTrigger.batch(".gs--bgVariableToDark", {
        start: "top 35%",
        onEnter: (elements, triggers) => {
            elements.forEach(element => {
                let tl = gsap.timeline();
                tl.to(element, {backgroundColor: "#0c0c0c", duration: 1})
                  .to(element.querySelectorAll(".is-animated-color-white"), {color: "#FFF", duration: 0.5, stagger: 0.3}, "-=0.65");
            });
        },
    });

    ScrollTrigger.batch(".gs--bgVariableToLight", {
        start: "top 35%",
        onEnter: (elements, triggers) => {
            elements.forEach(element => {
                let tl = gsap.timeline();
                tl.to(element, {backgroundColor: "#FFFFFF", duration: 1})
                  .to(element.querySelectorAll(".is-animated-color-dark"), {color: "#0c0c0c", duration: 0.5, stagger: 0.3}, "-=0.65");
            });
        },
    });
    



    ScrollTrigger.batch(".gs--bgVariableAll", {
        start: "top 35%",
        onEnter: (elements, triggers) => {
            elements.forEach(element => {
                let tl = gsap.timeline();
                tl.to(element, {backgroundColor: "#FFF", duration: 1});
    
                // Seleziona tutti gli elementi con la classe comune per l'animazione
                const animatedElements = element.querySelectorAll(".is-animated-color");
    
                // Applica l'animazione con effetto stagger
                tl.to(animatedElements, {
                    color: "#0c0c0c", // Puoi cambiare questa proprietà in base a ciò che vuoi animare
                    opacity: 1,
                    y: 0,
                    x: 0,
                    duration: 0.5,
                    stagger: 0.2 // Regola il valore dello stagger come preferisci
                }, "-=0.65");
            });
        },
    });
    

    ScrollTrigger.batch(".gs--fadeInToOpacity", {
        start: "top 70%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, duration: 0.5, y: 0, scrub: true });
        },
    });


}
gsapPortfolio();




const videos = document.querySelectorAll('video.video-is-parallax');
videos.forEach(video => {
    gsap.to(video, {
        scrollTrigger: {
            trigger: video,
            start: "top 30%",
            end: "bottom top",
            scrub: true
        },
        y: "30%", // Sposta il video verso l'alto durante lo scroll
        ease: "ease-in-out"
    });
});

